import React from "react"
import Router from "./app/router/Router"
import {createTheme, ThemeProvider} from "@mui/material"
import {UserContext} from "./app/context/userContext"
import "react-image-gallery/styles/scss/image-gallery.scss"
import "./styles/globals.scss"
import useFetchUserInfo from "./app/hooks/useFetchUserInfo"
import GuestRouter from "./app/router/GuestRouter"
import useLoading from "./app/hooks/useLoading"
import {LoadingContext} from "./app/context/useLoading"
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  PointElement,
  LineElement,
} from "chart.js"
import {CallCenterLayout} from "./app/layouts/CallCenterLayout"

const MuiTheme = createTheme({
  palette: {
    primary: {
      main: "#0B5534",
    },
    secondary: {
      main: "#4DB84E",
      contrastText: "#fff",
    },
    text: {
      primary: "#231F20",
    },
  },
})


ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title, PointElement, LineElement)

const App: React.FC = () => {
  const {isLoading, setLoading} = useLoading()
  const {userInfo, updateUserInfo} = useFetchUserInfo(setLoading)

  const loadingContextInfo = {isLoading, setLoading}
  const userContextInfo = React.useMemo(
    () => ({
      userInfo,
      updateUserInfo,
    }),
    [userInfo, isLoading],
  )

  if (isLoading && userInfo === undefined) return <div />
  return (
    <>
      <ThemeProvider theme={MuiTheme}>
        <LoadingContext.Provider value={loadingContextInfo}>
          <UserContext.Provider value={userContextInfo}>{userInfo ? <Router /> : <GuestRouter />}</UserContext.Provider>
        </LoadingContext.Provider>
      </ThemeProvider>
      <div id="portal-root" />
    </>
  )
}

export default App
